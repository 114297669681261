<template>
    <div class="page-wrapper">
        <div class="main-title-wrap" hero-transition-group>
            <img
                src="@/assets/img/pastille.svg"
                class="pastille"
                alt="Houblon Mellön"
                data-inview="fadeInUp"
                data-delay="100"
            />
            <h1 class="title special" data-inview="fadeInUp" data-delay="200"><span v-html="contentIsLoaded ? content.introSimple1.surtitre : '&nbsp;'"></span></h1>
            <svg
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                data-inview="fadeInUp"
                data-delay="200"
            >
                <path
                    d="M2.59342 15L7.49582 10.0976L12.3982 15L15 12.3982L10.0976 7.50418L15 2.60178L12.3982 0L7.49582 4.9024L2.59342 0L0 2.60178L4.89403 7.50418L0 12.3982L2.59342 15Z"
                    fill="#BEA851"
                />
            </svg>
            <h2 class="title" data-inview="fadeInUp" data-delay="300">{{ $t('Événements passés') }}</h2>
        </div>

        <div class="events-list" hero-transition-group>
            <router-link
                :to="item.page.jsonUrl"
                class="event-wrap"
                data-inview="fadeInUp"
                data-delay="100"
                v-for="(item) in listeEvenements" :key="item.page.id"
            >
                <div class="img">
                    <img :src="item.page.imageSimple.evenement.url" class="main-img-beer" :alt="item.page.imageSimple.evenement.titre" />
                </div>
                <div class="infos">
                    <div class="name">
                        <h4 class="title">{{ item.page.titre }}</h4>
                    </div>
                    <div class="date">
                        <p class="text">{{ parseDate(item.page.dateDeLevenement.date) }}</p>
                        <div class="arrow">
                            <svg
                                width="25"
                                height="17"
                                viewBox="0 0 25 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M0 7.61401H23.6702V8.91004H0V7.61401Z" fill="#2E3E30" />
                                <path
                                    d="M16.7226 0L25 8.31128L16.7226 16.59L15.7915 15.6588L23.1393 8.31101L15.7915 0.930929L16.7226 0Z"
                                    fill="#2E3E30"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            </router-link>
        </div>

        <div class="cta-wrap">
            <router-link :to="{ name: 'Evenements-' + $route.meta.lang }" class="cta" data-inview="scale" data-delay="100">
                <p class="text">{{ $t('Voir les événements actuels') }}</p>
            </router-link>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from 'vuex'

import moment from 'moment'

import { initInViewAnimations } from '@/plugins/InViewAnimations'

export default {
    name: 'EvenementsIndex',

    components: {},

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        initInViewAnimations()
                    }, 1)
                }
            },
            immediate: true,
        },
    },

    computed: {
        listeEvenements() {
            if (!this.pageIsLoaded) {
                return []
            }

            // Return max of 4 items
            return this.globals.evenementEntry.data.filter(ev => ev.page.dateDeLevenement.date < moment().format('YYYY-MM-DD')).reverse()
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        parseDate(date) {
            moment.locale(this.$route.meta.lang)
            return moment(date).format('DD MMMM YYYY')
        }
    },
}
</script>
